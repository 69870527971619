import { useContext } from 'react';
import XS from '@ww-digital/xs-sdk';

import wwUtility from '../../../ww.utility.ts';
import { MarketUtility } from '../MarketUtility.ts';
import Storage from '@ww-digital/web-palette-react/dist/components/Utility/Storage';
import GUA from '@ww-digital/xs-plugin-gua';
import type { MarketContextType } from '../../../context/market.context.ts';
import { MarketContext } from '../../../context/market.context.ts';
import { useLocation } from 'react-router-dom';

export const ExposeCustomFilter = (
  experimentTextId: string,
  customFilter: any,
) => {
  const { country, language } = useContext<MarketContextType>(MarketContext);
  const visitorId = Storage.getCookieValue('ww_browser_id', false);
  const gua = new GUA();
  const { pathname } = useLocation();
  const locale = MarketUtility.getXSLocale(country, language);
  const baseURL = wwUtility.getAPIDomain();

  function runExposure() {
    (async () => {
      const xs = new XS({
        user: {
          visitorId: typeof visitorId === 'string' ? visitorId : '',
          locale: locale,
        },
        baseUrl: baseURL,
        plugins: [gua],
      });

      await xs.fetchUserExperiments();

      // Run Exposure for Test
      await xs.exposeExperiment(experimentTextId);
    })();
  }

  if (wwUtility.isBrowser()) {
    if (customFilter?.pages.includes(pathname)) {
      if (
        !!sessionStorage.getItem(customFilter.key) &&
        sessionStorage.getItem(customFilter.key) === customFilter.value
      ) {
        runExposure();
      } else if (
        !!localStorage.getItem(customFilter.key) &&
        localStorage.getItem(customFilter.key) === customFilter.value
      ) {
        runExposure();
      } else {
        window.addEventListener('storage', storageEventHandler, true);

        function storageEventHandler() {
          if (
            (!!sessionStorage.getItem(customFilter.key) &&
              sessionStorage.getItem(customFilter.key) ===
                customFilter.value) ||
            (!!localStorage.getItem(customFilter.key) &&
              localStorage.getItem(customFilter.key) === customFilter.value)
          ) {
            runExposure();
            window.removeEventListener('storage', storageEventHandler);
          }
        }
      }
    }
  }
};
