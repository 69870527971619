import _ from 'lodash';
import { TemplatesAttributeType } from './types.ts';
import { SliceContainerProps } from '../../Slices/SliceContainer/SliceContainer';

export const getDynamicAlterProps = (
  variableProps: TemplatesAttributeType['variableProps'],
  alterProps: TemplatesAttributeType['alterProps'],
  slices: any,
) => {
  if (variableProps !== undefined) {
    const sliceMap: Map<string, any> = new Map(
      slices.map((slice: SliceContainerProps['slice']) => [slice.id, slice]),
    );

    for (const propPath in variableProps) {
      const referenceSliceId = variableProps[propPath].split('.')[0];
      const referenceSlice = sliceMap.get(referenceSliceId);
      if (referenceSlice) {
        const referenceSlicePropPath = variableProps[propPath].split(
          `${referenceSliceId}.`,
        )[1];
        _.set(
          alterProps,
          propPath,
          _.get(referenceSlice, referenceSlicePropPath),
        );
      }
    }
  }
};
