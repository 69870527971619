import { GlobalCalendarType } from './types.ts';
import { isMatchedDate } from './isMatchedDate.ts';

export const pauseExperiment = (
  dates: GlobalCalendarType['dates'] = [],
  timezone: GlobalCalendarType['timezone'],
  dateFormat: GlobalCalendarType['dateFormat'],
): boolean => {
  return dates.some((date) => {
    return (
      isMatchedDate(date.startDate, date.endDate, timezone, dateFormat) &&
      date.pause
    );
  });
};
