import _ from 'lodash';
import wwSignupSettings from '@ww-digital/ww-signup-settings';

import wwUtility from '../../ww.utility.ts';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const wwConfig = require('../../ww.config.ts');

export interface ConfigMarket {
  preD8: boolean;
  country: string;
  hreflang: string;
  href: string;
  locale: string;
  applangaLocale: string;
  workshop: string;
  coaches?: string;
  coachesUrl?: string;
  recipe: string;
  meal: string;
  blog: string;
  author: string;
  reviewer: string;
  plans: string;
  priceDetailsTemplate: string;
  promoPaths: string[];
}

export interface ConfigMarkets {
  markets: Record<string, Record<string, ConfigMarket>>;
  allowedQueryStringParameters: string[];
}

export interface MarketInfo {
  country: string;
  language: string;
  languages: string[];
  hrefMap: ConfigMarket;
  defaultLanguage: string;
  isMultilingual: boolean;
  marketBasePath: string;
  plans: string;
}

export interface MarketsInfo {
  [x: string]: {
    [x: string]: MarketInfo;
  };
}

export interface CountryLanguage {
  country: string;
  language: string;
}

export class MarketUtility {
  static markets: ConfigMarkets['markets'] = wwConfig.markets;

  // Build all of the market info for all languages.
  static marketsInfo: MarketsInfo = _.mapValues(
    wwConfig.markets as ConfigMarkets['markets'],
    (countryLanguages, country) => {
      return _.mapValues(countryLanguages, (languageMap, language) => {
        const languages = Object.keys(countryLanguages);
        const defaultLanguage = languages[0];
        const isMultilingual = languages.length > 1;
        const marketBasePath = isMultilingual
          ? `/${country}/${language}`
          : `/${country}`;

        return {
          country,
          language,
          languages,
          hrefMap: languageMap,
          defaultLanguage,
          isMultilingual,
          marketBasePath,
          plans: countryLanguages[language].plans,
        };
      });
    },
  );

  static getCountryInfo(country: string) {
    if (!MarketUtility.isValidCountry(country)) {
      return null;
    }

    const countryLanguages = MarketUtility.marketsInfo[country];
    const defaultLanguage = Object.keys(countryLanguages)[0];

    return countryLanguages[defaultLanguage];
  }

  static getMarketInfo(country: string, language: string) {
    // If this is not a valid market, return null.
    if (!MarketUtility.isValidMarket(country, language)) {
      return null;
    }

    return MarketUtility.marketsInfo[country][language];
  }

  static getMarketField(country: string, language: string, field: string) {
    return wwUtility.getMarketField(country, language, field);
  }

  static getXSLocale(country: string, language: string) {
    let locale: string = wwUtility.getMarketField(
      country,
      language,
      'hreflang',
    );
    if (locale === 'en-NZ') {
      locale = 'en-AU';
    }
    return locale;
  }

  static localeRules(country: string, language: string) {
    let locale = wwUtility.getMarketField(country, language, 'hreflang');
    if (locale === 'en-NZ') {
      locale = 'en-AU';
    }
    if (!locale) return null;

    const rules = wwSignupSettings.getRules([locale]);
    return rules ? rules[locale] : null;
  }

  static wwLocale(country: string, language: string) {
    const locale = wwUtility.getMarketField(country, language, 'hreflang');

    if (!locale) return null;

    const locales = wwSignupSettings.getLocales([locale])?.[locale] || null;

    return locales;
  }

  static isValidCountry(country: string) {
    return country in MarketUtility.markets;
  }

  static isValidMarket(country: string, language: string) {
    // If this is not a valid country, return false.
    if (!MarketUtility.isValidCountry(country)) {
      return false;
    }

    return language in MarketUtility.markets[country];
  }

  static getHrefMap() {
    return _.flatMap(MarketUtility.markets, (countryMarket) =>
      _.map(countryMarket, (hrefMap) => hrefMap),
    );
  }

  static getCountryHrefMap(detectedCountry: string) {
    return MarketUtility.getHrefMap().filter(
      (hrefCountryInfo) => hrefCountryInfo.country === detectedCountry,
    );
  }

  static getCoachPath = (country: string, language: string) => {
    const workshop = wwUtility.getMarketField(country, language, 'workshop');
    const coaches = wwUtility.getMarketField(country, language, 'coaches');
    const validPath =
      workshop &&
      coaches &&
      wwUtility.getMarketField(country, language, 'coachesUrl');

    return validPath ? `/${workshop}/${coaches}` : '';
  };

  static getContributorPath = (
    type: string,
    country: string,
    language: string,
  ) => {
    const path = wwUtility.getMarketField(country, language, type);

    return path ? `/${path}` : '';
  };

  static extractCountryLanguageFromURL(url: string): CountryLanguage {
    const result = { country: '', language: '' };
    if (!url) {
      return result;
    }

    // Extract path from the absolute URL
    const regex = new RegExp('^https?:\\/\\/[A-Za-z0-9:.]*([\\/]{1}.*\\/?)$');
    const match = regex.exec(url);
    const path = match ? match[1] : '';
    if (!path) {
      return result;
    }

    // Extract path components (excluding the first empty element)
    const pathComponents = path.split('/').slice(1);

    // Check for direct country match (e.g., /us/)
    const marketData = wwConfig.markets[pathComponents[0]];
    if (marketData) {
      result.country = pathComponents[0];

      // Check for direct language match (e.g., /ca/en/) on multi-language markets
      // For single-language markets, the language is assumed to be the only one
      const languages = Object.keys(marketData);
      if (languages.length > 1 && languages.includes(pathComponents[1])) {
        result.language = pathComponents[1];
      } else {
        result.language = languages[0];
      }
    }

    return result;
  }
}
