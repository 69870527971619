import React, { useContext } from 'react';

export const StoryStreamSliceContainer = (): JSX.Element => {
  return (
    <>
      <div id="stry-wrapper"></div>
      <script
        type="text/javascript"
        src="https://apps.storystream.ai/app/js/ZmQ2NDMyNDAxZWY2N2RmMzk4.js"
      ></script>
    </>
  );
};
