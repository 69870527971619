import _ from 'lodash';
import * as ApplangaJS from '@ww-digital/applanga-js';

import type { CoinProps } from '@ww-digital/web-palette-react/dist/components/Coin/Coin';
import type { FoodSuggestion } from '../Regions/FoodSuggestionsContainer/FoodSuggestionsContainer';

import { AppUtility } from './AppUtility.ts';
import { Domain } from './Domain.ts';
import wwUtility from '../../ww.utility.ts';

export interface FoodType {
  id: string;
  type?: string;
  slug?: string;
  sourceType?: string;
  contentTags?: ContentTags[];
}

interface ContentTags {
  tags: string[];
}

interface AriaLabelsType {
  valueLabel?: string;
  rangeLabel?: string;
}

interface ValueType {
  points?: number;
  maxPointsHigh?: number;
  maxPointsLow?: number;
}

export class FoodUtility {
  static cmxDomain = new Domain({
    subdomain: 'cmx',
  });

  // Get the CMX URL.
  static getMemberURL(
    food: FoodType,
    country: string,
    language: string,
  ): string {
    const environment = AppUtility.getEnv();

    const domain = FoodUtility.cmxDomain.getEndpoint(
      environment,
      country,
      language,
    );

    return `${domain}/details/${food.sourceType}:${food.id}`;
  }

  static getDeepLinkURL(
    food: FoodType,
    country: string,
    language: string,
  ): string {
    // CMX always uses prod for deep linking, so doing the same here.
    const domain = FoodUtility.cmxDomain.getEndpoint('prod', country, language);

    return `${domain}/deeplink/item-details?type=${food.sourceType}&id=${food.id}`;
  }

  static getURL(
    food: FoodType | FoodSuggestion,
    country: string,
    language: string,
  ): string {
    const type = food.type?.toLowerCase() || '';

    const foodTypeSlug = wwUtility.getMarketField(country, language, type);

    return `/${foodTypeSlug}/${food.slug}/${food.id}`;
  }

  // Return content data needed for DFP Ads.
  static getAdsContentData(food: FoodType) {
    return {
      id: food.id,
      type: food.sourceType === 'WWMEAL' ? 'meal' : 'recipe',
      tags: _.flatten(food.contentTags?.map((item: ContentTags) => item.tags)),
    };
  }

  // Return Points Value(s) and Aria Label
  static getPointsData(values: ValueType, ariaLabels: AriaLabelsType) {
    const data: CoinProps = {};

    data.label =
      typeof values.points === 'number' &&
      typeof ariaLabels.valueLabel === 'string'
        ? ApplangaJS.get(ariaLabels.valueLabel || '', {
            value: values.points.toString(),
          })
        : '';

    data.value = values.points;

    return data;
  }
}
