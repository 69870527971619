import { NormalizedExperiments } from '@ww-digital/xs-sdk';

export const filterExperimentTextIDs = (
  normalizedExperiments: NormalizedExperiments,
  queryString: Record<string, string>,
  activeTestStatus: number,
): string[] => {
  const normExpTextIDs = Object.keys(normalizedExperiments);
  return normExpTextIDs.filter((expTextId) =>
    !queryString.exptextid && !queryString.varid && !queryString.xspreviewtests
      ? expTextId.includes('XS-Template') &&
        normalizedExperiments[expTextId]?.status === activeTestStatus
      : expTextId.includes('XS-Template'),
  );
};
