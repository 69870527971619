import React, { ReactNode, useContext } from 'react';
import { XSProvider } from '@ww-digital/xs-sdk-react';
import { useQuery } from 'react-apollo';
import { useLocation } from 'react-router-dom';
import GUA from '@ww-digital/xs-plugin-gua';
import _ from 'lodash';
import { QueryStringUtility } from '../../Utility/QueryStringUtility.ts';

import type { NormalizedExperiments } from '@ww-digital/xs-sdk';
import type { MarketContextType } from '../../../context/market.context';
import type { ExperimentContextType } from '../../../context/experiment.context';

import Storage from '@ww-digital/web-palette-react/dist/components/Utility/Storage';
import { MarketUtility } from '../../Utility/MarketUtility.ts';
import { getExperimentsPreviewHeader } from '../../Utility/xsUtility/xsPreview.tsx';
import { isDisableXS } from '../../Utility/xsUtility/xsDisable.tsx';
import { MarketContext } from '../../../context/market.context.ts';
import { wwTests } from '../../../ww.tests.ts';
import { ExperimentContext } from '../../../context/experiment.context.ts';
import wwUtility from '../../../ww.utility.ts';
import { filterExperiments } from '../../Utility/xsUtility/filterExperiments.ts';
import { filterExperimentTextIDs } from '../../Utility/xsUtility/filterExperimentTextIDs.ts';
import { combineTestAutoRoutes } from '../../Utility/xsUtility/combineTestAutoRoutes.ts';
import { cleanUpTestAutoRoutes } from '../../Utility/xsUtility/cleanUpTestAutoRoutes.ts';
import { filterExperimentsByPagePath } from '../../Utility/xsUtility/filterExperimentsByPagePath.ts';

import ExperimentsQuery from './graphql/ExperimentsQuery.graphql';
import { AppProps } from '../../App';

export interface AppXSProviderProps {
  children: ReactNode;
  ssr: boolean;
  experiments?: ExperimentContextType['experiments'];
  experimentsHeader?: string;
  experimentsFranchise?: string;
  url: AppProps['url'];
  cookies?: string;
}

export const AppXSProvider = ({
  children,
  ssr,
  experiments: experimentsInit,
  experimentsHeader,
  experimentsFranchise,
  url,
  cookies,
}: AppXSProviderProps): JSX.Element => {
  const visitorId = Storage.getCookieValue('ww_browser_id', false);
  const queryString = QueryStringUtility.getQueryParams(url.search);
  const { country, language } = useContext<MarketContextType>(MarketContext);
  const { loading, error, data } = useQuery(ExperimentsQuery);
  const { pathname } = useLocation();
  const experiments = experimentsInit || {};
  const gua = new GUA();
  const activeTestStatus = 2;
  let mergedTests = wwTests;
  let experimentsPreviewHeader = '';
  let contextExperimentsHeader = '';

  // Check whether test is in ww.tests.ts file
  if (experiments && data?.xsExperiments && !loading && !error) {
    const normalizedExperiments: NormalizedExperiments =
      data.xsExperiments.experiments;

    const vsExpTextIDs = filterExperimentTextIDs(
      normalizedExperiments,
      queryString,
      activeTestStatus,
    );

    let updatableWWTests = _.cloneDeep(wwTests);
    updatableWWTests = combineTestAutoRoutes(
      vsExpTextIDs,
      normalizedExperiments,
      updatableWWTests,
    );

    updatableWWTests = cleanUpTestAutoRoutes(
      updatableWWTests,
      country,
      language,
    );

    mergedTests = _.merge({}, wwTests, updatableWWTests);

    filterExperimentsByPagePath(
      mergedTests,
      country,
      language,
      pathname,
      normalizedExperiments,
      experimentsFranchise,
      experiments,
    );

    if (!isDisableXS(url.search)) {
      // get the experiments preview header
      experimentsPreviewHeader = getExperimentsPreviewHeader(
        experimentsHeader,
        normalizedExperiments,
        url.search,
      );

      contextExperimentsHeader =
        experimentsPreviewHeader || experimentsHeader || '';
    }
  }

  return (
    <ExperimentContext.Provider
      value={{
        experiments,
        bucketedExperiments: filterExperiments(
          experiments,
          contextExperimentsHeader,
          country,
        ),
        experimentsHeader: contextExperimentsHeader,
        tests: mergedTests,
        cookies: cookies !== undefined ? cookies : '',
      }}
    >
      <XSProvider
        user={{
          visitorId: typeof visitorId === 'string' ? visitorId : '',
          locale: MarketUtility.getXSLocale(country, language),
        }}
        baseUrl={wwUtility.getAPIDomain()}
        isServerSide={ssr}
        plugins={[gua]}
      >
        {children}
      </XSProvider>
    </ExperimentContext.Provider>
  );
};
