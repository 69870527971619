// Common data for WW operations.
module.exports = {
  // Default language should be listed first.
  markets: {
    au: {
      en: {
        preD8: false,
        country: 'au',
        hreflang: 'en-AU',
        href: '/au/',
        locale: 'au',
        applangaLocale: 'en-AU',
        workshop: 'find-a-workshop',
        coaches: 'browse-coaches',
        coachesUrl: 'en_AU/coaches.html',
        recipe: 'recipe',
        meal: 'meal',
        blog: 'blog',
        author: 'authors',
        reviewer: 'reviewers',
        plans: 'plans',
        priceDetailsTemplate: 'A',
        promoPaths: [],
      },
    },
    be: {
      nl: {
        preD8: false,
        country: 'be',
        hreflang: 'nl-BE',
        href: '/be/nl/',
        locale: 'nb',
        applangaLocale: 'nl-BE',
        workshop: 'vind-een-workshop',
        recipe: 'recept',
        meal: 'maaltijd',
        blog: 'blog',
        author: 'auteurs',
        reviewer: 'beoordelaars',
        plans: 'prijzen',
        priceDetailsTemplate: 'B',
        promoPaths: [],
      },
      fr: {
        preD8: false,
        country: 'be',
        hreflang: 'fr-BE',
        href: '/be/fr/',
        locale: 'fb',
        applangaLocale: 'fr-BE',
        workshop: 'trouvez-un-atelier',
        recipe: 'recette',
        meal: 'repas',
        blog: 'blog',
        author: 'auteurs',
        reviewer: 'evaluateurs',
        plans: 'prix',
        priceDetailsTemplate: 'B',
        promoPaths: [],
      },
    },
    ca: {
      en: {
        preD8: false,
        country: 'ca',
        hreflang: 'en-CA',
        href: '/ca/en/',
        locale: 'ca',
        applangaLocale: 'en-CA',
        workshop: 'find-a-workshop',
        coaches: 'browse-coaches',
        coachesUrl: 'en_CA/coaches.html',
        recipe: 'recipe',
        meal: 'meal',
        blog: 'blog',
        author: 'authors',
        reviewer: 'reviewers',
        plans: 'plans',
        priceDetailsTemplate: 'A',
        promoPaths: [],
      },
      fr: {
        preD8: false,
        country: 'ca',
        hreflang: 'fr-CA',
        href: '/ca/fr/',
        locale: 'fc',
        applangaLocale: 'fr-CA',
        workshop: 'trouvez-un-atelier',
        coaches: 'choisir-son-coach',
        coachesUrl: 'fr_CA/coaches.html',
        recipe: 'recette',
        meal: 'repas',
        blog: 'blog',
        author: 'auteurs',
        reviewer: 'evaluateurs',
        plans: 'prix',
        priceDetailsTemplate: 'A',
        promoPaths: [],
      },
    },
    ch: {
      de: {
        preD8: false,
        country: 'ch',
        hreflang: 'de-CH',
        href: '/ch/de/',
        locale: 'ds',
        applangaLocale: 'de-CH',
        workshop: 'finde-einen-workshop',
        recipe: 'rezept',
        meal: 'mahlzeit',
        blog: 'blog',
        author: 'redaktion',
        reviewer: 'experten',
        plans: 'preise',
        priceDetailsTemplate: 'C',
        promoPaths: [],
      },
      fr: {
        preD8: false,
        country: 'ch',
        hreflang: 'fr-CH',
        href: '/ch/fr/',
        locale: 'fs',
        applangaLocale: 'fr-CH',
        workshop: 'trouvez-un-atelier',
        recipe: 'recette',
        meal: 'repas',
        blog: 'blog',
        author: 'redaction',
        reviewer: 'evaluateurs',
        plans: 'prix',
        priceDetailsTemplate: 'C',
        promoPaths: [],
      },
    },
    de: {
      de: {
        preD8: false,
        country: 'de',
        hreflang: 'de-DE',
        href: '/de/',
        locale: 'de',
        applangaLocale: 'de-DE',
        workshop: 'finde-einen-workshop',
        recipe: 'rezept',
        meal: 'mahlzeit',
        blog: 'blog',
        author: 'redaktion',
        reviewer: 'experten',
        plans: 'preise',
        priceDetailsTemplate: 'C',
        promoPaths: [],
      },
    },
    fr: {
      fr: {
        preD8: false,
        country: 'fr',
        hreflang: 'fr-FR',
        href: '/fr/',
        locale: 'fr',
        applangaLocale: 'fr-FR',
        workshop: 'trouvez-un-atelier',
        coaches: 'choisir-son-coach',
        coachesUrl: 'fr/coaches.html',
        recipe: 'recette',
        meal: 'menu',
        blog: 'blog',
        author: 'auteurs',
        reviewer: 'relecteurs',
        plans: 'prix',
        priceDetailsTemplate: 'B',
        promoPaths: [],
      },
    },
    nl: {
      nl: {
        preD8: false,
        country: 'nl',
        hreflang: 'nl-NL',
        href: '/nl/',
        locale: 'nl',
        applangaLocale: 'nl-NL',
        workshop: 'vind-een-workshop',
        recipe: 'recept',
        meal: 'maaltijd',
        blog: 'blog',
        author: 'auteurs',
        reviewer: 'beoordelaars',
        plans: 'prijzen',
        priceDetailsTemplate: 'A',
        promoPaths: [],
      },
    },
    nz: {
      en: {
        preD8: false,
        country: 'nz',
        hreflang: 'en-NZ',
        href: '/nz/',
        locale: 'nz',
        applangaLocale: 'en-NZ',
        workshop: 'find-a-workshop',
        coaches: 'browse-coaches',
        coachesUrl: 'en_AU/coaches.html',
        recipe: 'recipe',
        meal: 'meal',
        blog: 'blog',
        author: 'authors',
        reviewer: 'reviewers',
        plans: 'plans',
        priceDetailsTemplate: 'A',
        promoPaths: [],
      },
    },
    se: {
      sv: {
        preD8: false,
        country: 'se',
        hreflang: 'sv-SE',
        href: '/se/',
        locale: 'se',
        applangaLocale: 'sv',
        workshop: 'har-finns-vi',
        recipe: 'recept',
        meal: 'meny',
        blog: 'artikel',
        author: 'forfattare',
        reviewer: 'granskare',
        plans: 'medlemskap',
        priceDetailsTemplate: 'A',
        promoPaths: [],
      },
    },
    uk: {
      en: {
        preD8: false,
        country: 'uk',
        hreflang: 'en-GB',
        href: '/uk/',
        locale: 'uk',
        applangaLocale: 'en-GB',
        workshop: 'find-a-workshop',
        coaches: 'browse-coaches',
        coachesUrl: 'en_GB/coaches.html',
        recipe: 'recipe',
        meal: 'meal',
        blog: 'blog',
        author: 'authors',
        reviewer: 'reviewers',
        plans: 'plans',
        priceDetailsTemplate: 'A',
        promoPaths: [],
      },
    },
    us: {
      en: {
        preD8: false,
        country: 'us',
        hreflang: 'en-US',
        href: '/us/',
        locale: 'us',
        applangaLocale: 'en-US',
        workshop: 'find-a-workshop',
        coaches: 'browse-coaches',
        coachesUrl: 'coaches.html',
        recipe: 'recipe',
        meal: 'meal',
        blog: 'blog',
        author: 'authors',
        reviewer: 'reviewers',
        plans: 'plans',
        priceDetailsTemplate: 'A',
        promoPaths: ['partners/crunch'],
      },
    },
  },
  allowedQueryStringParameters: [
    // GQL domain override
    'env',
    // Preview mode.
    'sid',
    'vid',
    // Pagination.
    'page',
    // Pricing
    'blAdminUserId',
    'blSandboxId',
    'blAuthToken',
    'blSandboxDateTime',
    'cmp',
    'bg',
    'pz',
    'channelId',
    'sponsorId',
    'promotionId',
    'locationId',
  ],
};
