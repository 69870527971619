import React from 'react';
import { HelmetProvider, FilledContext } from 'react-helmet-async';

import { ContainerProvider } from '@ww-digital/web-palette-react/dist/components/Container/ContainerProvider/ContainerProvider';
import { LinkContext } from '@ww-digital/web-palette-react/dist/context/link.context';
import { OverlayProvider } from '@ww-digital/web-palette-react/dist/components/Overlay/OverlayProvider/OverlayProvider';

import type { ApolloProviderProps } from './Provider/Apollo/ApolloProvider';
import type { ExperimentContextType } from '../context/experiment.context';
import type { ConfigProviderProps } from '../components/Config/ConfigProvider';

import ErrorBoundary from './Errors/ErrorBoundary/ErrorBoundary.tsx';
import { ApolloProvider } from './Provider/Apollo/ApolloProvider.tsx';
import { MarketProvider } from './Provider/MarketProvider.tsx';
import { EntitlementProvider } from './Provider/EntitlementProvider.tsx';
import { DisclaimerProvider } from './Provider/DisclaimerProvider.tsx';
import { SectionProvider } from './Provider/SectionProvider/SectionProvider.tsx';
import { GeoBannerContainer } from './Banners/GeoBannerContainer/GeoBannerContainer.tsx';
import { GlobalMetadata } from './Metadata/GlobalMetadata/GlobalMetadata.tsx';
import { ChatContainer } from './ChatContainer/ChatContainer.tsx';
import { GDPRBannerContainer } from './Banners/GDPRBannerContainer/GDPRBannerContainer.tsx';
import { Router } from './Router.tsx';
import { Routes } from './Routes.tsx';
import { HeaderContainer } from './Regions/HeaderContainer/HeaderContainer.tsx';
import { FooterContainer } from './Regions/FooterContainer/FooterContainer.tsx';
import { ScrollRestoration } from './Utility/ScrollRestoration/ScrollRestoration.tsx';
import { SkipLinksContainer } from './Regions/SkipLinksContainer/SkipLinksContainer.tsx';
import { QueryStringProvider } from './Provider/QueryStringProvider.tsx';
import { ConfigProvider } from './Config/ConfigProvider.tsx';
import { AppXSProvider } from './Provider/AppXSProvider/AppXSProvider.tsx';
import { VSLink } from './Utility/VSLink.tsx';

export interface AppProps {
  helmetContext?: FilledContext;
  experiments?: ExperimentContextType['experiments'];
  experimentsHeader?: string;
  experimentsFranchise?: string;
  translations?: ConfigProviderProps['translations'];
  apolloManager: ApolloProviderProps['manager'];
  error?: boolean;
  ssr?: boolean;
  url: URL | Location;
  cookies?: string;
}

export const App = ({
  helmetContext,
  apolloManager,
  experiments,
  experimentsHeader,
  experimentsFranchise,
  translations,
  error,
  ssr,
  url,
  cookies,
}: AppProps): JSX.Element => {
  return (
    <HelmetProvider context={helmetContext}>
      <Router ssr={ssr} url={url}>
        <QueryStringProvider>
          <MarketProvider url={url}>
            <EntitlementProvider>
              <ErrorBoundary error={error}>
                <ApolloProvider manager={apolloManager}>
                  <ConfigProvider translations={translations}>
                    <AppXSProvider
                      ssr={ssr || false}
                      experiments={experiments}
                      experimentsHeader={experimentsHeader}
                      experimentsFranchise={experimentsFranchise}
                      url={url}
                      cookies={cookies}
                    >
                      <OverlayProvider>
                        <DisclaimerProvider>
                          <SectionProvider>
                            <ContainerProvider>
                              <LinkContext.Provider value={VSLink}>
                                <ScrollRestoration>
                                  <GlobalMetadata />
                                  <SkipLinksContainer />
                                  <GeoBannerContainer />
                                  <HeaderContainer />
                                  <Routes />
                                  <FooterContainer />
                                  <ChatContainer />
                                  <GDPRBannerContainer />
                                </ScrollRestoration>
                              </LinkContext.Provider>
                            </ContainerProvider>
                          </SectionProvider>
                        </DisclaimerProvider>
                      </OverlayProvider>
                    </AppXSProvider>
                  </ConfigProvider>
                </ApolloProvider>
              </ErrorBoundary>
            </EntitlementProvider>
          </MarketProvider>
        </QueryStringProvider>
      </Router>
    </HelmetProvider>
  );
};
