import React, { ReactNode, useContext } from 'react';
import { useQuery } from 'react-apollo';
import { Route, useParams, useRouteMatch } from 'react-router-dom';
import * as ApplangaJS from '@ww-digital/applanga-js';
import _ from 'lodash';

import type { MarketContextType } from '../../../context/market.context';
import type { EntitlementContextType } from '../../../context/entitlement.context';
import type { ConfigContextType } from '../../../context/config.context';

import { Recipe } from '@ww-digital/web-palette-react/dist/components/Food/Recipe/Recipe';

import { JSONLD } from '../../Metadata/JSONLD/JSONLD.tsx';
import { FourOhFourRoute } from '../FourOhFourRoute/FourOhFourRoute.tsx';
import { Redirect } from '../../Redirects/Redirect/Redirect.tsx';
import { RouteMetadata } from '../../Metadata/RouteMetadata/RouteMetadata.tsx';
import { AdsContainer } from '../../AdsContainer/AdsContainer.tsx';
import { GTM } from '../../GTM/GTM.tsx';
import { EmailCaptureModalContainer } from '../../Modals/EmailCaptureModalContainer/EmailCaptureModalContainer.tsx';
import { MeteredContentContainer } from '../../Modals/MeteredContentContainer/MeteredContentContainer.tsx';
import { AppUtility } from '../../Utility/AppUtility.ts';
import { FoodUtility } from '../../Utility/FoodUtility.ts';
import { FoodSuggestionsContainer } from '../../Regions/FoodSuggestionsContainer/FoodSuggestionsContainer.tsx';
import { EntitlementRedirect } from '../../Redirects/EntitlementRedirect/EntitlementRedirect.tsx';
import { ConfigContext } from '../../../context/config.context.ts';
import { MarketContext } from '../../../context/market.context.ts';
import { EntitlementContext } from '../../../context/entitlement.context.ts';

import RecipeQuery from './graphql/RecipeQuery.graphql';

interface IngredientDataType {
  ingredientName: string;
  note: string;
  portionName: string;
  quantity: string;
}

interface InstructionDataType {
  instruction: string;
}

interface ContentTagDataType {
  tags: string[];
  tagType: string;
}

interface RecipeParams {
  slug: string;
  id: string;
}

interface FoodMastheadProps {
  label: string;
  value: string | string[] | ReactNode[];
}

const noImageFood =
  'https://v.cdn.ww.com/media/system/cms/us/no-image-food.png?auto=webp&optimize=medium';

export const RecipeRoute = (): JSX.Element => {
  const { country } = useContext<MarketContextType>(MarketContext);
  const { entitlement } =
    useContext<EntitlementContextType>(EntitlementContext);
  const { translations, config } = useContext<ConfigContextType>(ConfigContext);
  const { slug, id } = useParams<RecipeParams>();
  const match = useRouteMatch();

  const formatTime = (
    time: number,
    timeFormat: string,
    hourFormat: string,
    minFormat: string,
  ) => {
    const hr = Math.floor(time / 60);
    const min = time % 60;

    const formattedMin = ApplangaJS.get(minFormat || '', {
      min,
    });

    // If there are no hours, just return the minutes.
    if (hr === 0) {
      return formattedMin;
    }

    const formattedHour = ApplangaJS.get(hourFormat || '', {
      hour: hr,
    });

    // If the minutes are 0, just return the hours.
    if (min === 0) {
      return formattedHour;
    }

    // Otherwise, return both hours and min.
    return ApplangaJS.get(timeFormat || '', {
      hour: formattedHour,
      min: formattedMin,
    });
  };

  const getContentTagsValue = (
    contentTags: ContentTagDataType[],
    types: string | string[],
  ) => {
    if (typeof types === 'string') {
      types = [types];
    }
    // Find objects that have matching tagType attributes,
    // build array of object.tags
    const list = _.map(types, (type) => {
      const tagMatch = _.find(contentTags, { tagType: type });
      return tagMatch ? tagMatch.tags.join() : null;
    });
    // Return comma separated string with no empty items
    return _.filter(list, (item) => item).join();
  };

  const fallback = `${AppUtility.getDomain(country)}${noImageFood}`;

  const { loading, error, data } = useQuery(RecipeQuery, {
    variables: {
      id,
      includeSuggestions: true,
    },
    errorPolicy: 'all',
  });

  if (error) {
    if (AppUtility.isNetworkError(error)) {
      throw new Error('Recipe network timeout.');
    }

    return <Route component={FourOhFourRoute} />;
  }

  // Return 'empty' Recipe Component so we can display SkeletonIndicators.
  if (loading) {
    return (
      <Recipe
        foodMasthead={{
          name: '',
        }}
        ingredients={{
          headline: '',
          ingredients: [],
        }}
        instructions={{
          headline: '',
          instructions: [],
        }}
        note={{
          headline: '',
          text: 'x', // Need a value here for SkeletonIndicator to appear for note.
        }}
        loading
      />
    );
  }

  // Data error or meal is inactive.
  if (!data || !data.recipe || !data.recipe.isActive) {
    return <Route component={FourOhFourRoute} />;
  }

  const { recipe } = data;

  const { facebook, twitter, pinterest, linkedin } =
    config.socialNetworks || {};

  const socialLabels = {
    facebookLabel: translations.GLOBAL_FACEBOOK_LABEL,
    twitterLabel: translations.GLOBAL_TWITTER_LABEL,
    pinterestLabel: translations.GLOBAL_PINTEREST_LABEL,
    linkedinLabel: translations.GLOBAL_LINKEDIN_LABEL,
  };

  // If slug in URL doesn't match slug from recipe,
  // redirect to correct URL
  if (slug !== recipe.recipeUrl) {
    return <Redirect to={`/recipe/${recipe.recipeUrl}/${recipe.id}`} />;
  }

  // Attribute rendering.
  const foodAttributes: FoodMastheadProps[] = [];
  if (recipe.totalTime || recipe.totalTime === 0) {
    foodAttributes.push({
      label: translations.FOOD_TOTAL_TIME_LABEL,
      value: formatTime(
        recipe.totalTime,
        translations.FOOD_TIME_FORMAT,
        translations.FOOD_HOUR_FORMAT,
        translations.FOOD_MIN_FORMAT,
      ),
    });
  }
  if (recipe.preparationTime || recipe.preparationTime === 0) {
    foodAttributes.push({
      label: translations.FOOD_PREP_LABEL,
      value: formatTime(
        recipe.preparationTime,
        translations.FOOD_TIME_FORMAT,
        translations.FOOD_HOUR_FORMAT,
        translations.FOOD_MIN_FORMAT,
      ),
    });
  }
  if (recipe.cookTime || recipe.cookTime === 0) {
    foodAttributes.push({
      label: translations.FOOD_COOK_LABEL,
      value: formatTime(
        recipe.cookTime,
        translations.FOOD_TIME_FORMAT,
        translations.FOOD_HOUR_FORMAT,
        translations.FOOD_MIN_FORMAT,
      ),
    });
  }
  if (recipe.servingSize) {
    foodAttributes.push({
      label: translations.FOOD_SERVES_LABEL,
      value: `${recipe.servingSize}`,
    });
  }
  if (recipe.difficultyLevel) {
    foodAttributes.push({
      label: translations.FOOD_DIFFICULTY_LABEL,
      value: recipe.difficultyLevel,
    });
  }

  // Ingredient rendering.
  const ingredients = recipe.ingredients.map(
    (ingredient: IngredientDataType) => ({
      name: ingredient.ingredientName,
      note: ingredient.note,
      portion: ingredient.portionName,
      quantity: ingredient.quantity,
    }),
  );

  const instructions = recipe.instructions.map((item: InstructionDataType) => ({
    instruction: item.instruction,
  }));

  const { contentTags } = recipe;

  const pointsValues = { points: recipe.points };

  const isOnePointRecipe = pointsValues.points === 1;

  const pointsTextConfigured = isOnePointRecipe
    ? translations.POINTS_POINTS_COIN_SERVING_TEXT
    : translations.POINTS_POINTS_COIN_SERVING_TEXT_PLURAL;

  const pointsAriaLabelConfigured = {
    valueLabel: isOnePointRecipe
      ? translations.POINTS_POINTS_COIN_ARIA_LABEL
      : translations.POINTS_POINTS_COIN_ARIA_LABEL_PLURAL,
  };

  // Get Food Points Values and Labels
  const pointsData = FoodUtility.getPointsData(
    pointsValues,
    pointsAriaLabelConfigured,
  );

  // Get Tooltip Values and Labels
  const tooltipData = {
    coinServingText: pointsTextConfigured,
    tooltipContent: translations.POINTS_POINTS_TOOLTIP_CONTENT,
    tooltipLinkText: translations.POINTS_POINTS_TOOLTIP_LINK_TEXT,
    tooltipLink: config.points.tooltipLink,
  };

  const recipeJsonLD = {
    '@type': 'Recipe',
    name: recipe.displayName,
    ...(recipe.image.url && {
      image: {
        '@type': 'ImageObject',
        url: recipe.image.url,
        name: ApplangaJS.get(translations.FOOD_IMAGE_OF_TEXT || '', {
          title: recipe.displayName,
        }),
      },
    }),
    description: recipe.description,
    nutrition: {
      calories: `${Math.ceil(recipe.nutrition.calories)} kcal`,
    },
    prepTime: `PT${recipe.preparationTime}M`,
    cookTime: `PT${recipe.cookTime}M`,
    totalTime: `PT${recipe.totalTime}M`,
    recipeIngredient: recipe.ingredients.map(
      (ingredient: IngredientDataType) => {
        const { quantity, portionName, ingredientName, note } = ingredient;
        if (ingredientName) {
          return [quantity, portionName, ingredientName, note]
            .filter((x) => x)
            .join(' ');
        }

        return null;
      },
    ),
    recipeInstructions: recipe.instructions.map(
      ({ instruction }: InstructionDataType) => ({
        '@type': 'HowToStep',
        text: instruction,
      }),
    ),
    recipeYield: `${recipe.servingSize} servings`,
    recipeCategory: getContentTagsValue(contentTags, 'food_course'),
    recipeCuisine: getContentTagsValue(contentTags, 'food_cuisines'),
    keywords: getContentTagsValue(contentTags, [
      'food_dietary consideration',
      'food_main ingredient',
      'food_cooking method',
      'food_dish type',
      'food_holidays and entertaining',
      'food_season',
    ]),
  };

  // Use capitalized entitlement to check ads config is enabled. ie enabledGuest.
  const enabledEntitlement = `enabled${
    _.upperFirst(entitlement) as EntitlementContextType['entitlement']
  }` as const;
  const showAds = config.ads[enabledEntitlement];
  // If no ads sidebar expects null.
  const ads = showAds ? (
    <AdsContainer content={FoodUtility.getAdsContentData(recipe)} />
  ) : null;

  const metatagTitle = ApplangaJS.get(translations.METATAG_RECIPE_TITLE || '', {
    name: recipe.displayName,
  });

  const metatagDescription = ApplangaJS.get(
    translations.METATAG_RECIPE_DESCRIPTION || '',
    {
      name: recipe.displayName,
      cook_time: recipe.cookTime,
    },
  );

  const imageAltTag = ApplangaJS.get(translations.FOOD_ALT_TAG || '', {
    recipeMeal: recipe.displayName,
  });

  const qrLinkUrl =
    entitlement === 'Member'
      ? FoodUtility.getDeepLinkURL(recipe, country, 'en')
      : '';
  const qrLinkUrlLabel =
    entitlement === 'Member' ? translations.FOOD_OPEN_DEEPLINK : '';

  return (
    <>
      <EntitlementRedirect
        food={{
          type: 'recipe',
          sourceType: recipe.sourceType,
          slug: recipe.recipeUrl,
          id: recipe.id,
        }}
      />
      <GTM key={`gtm-${recipe.id}`} category="recipe" />
      <RouteMetadata
        title={`${metatagTitle} | ${translations.METATAG_SITE_NAME}`}
        description={metatagDescription}
        og={{ type: 'article' }}
        image={recipe.image.url}
      />
      <JSONLD key="jsonLd" content={recipeJsonLD} />
      <Recipe
        social={{
          media: recipe.image.url || fallback,
          url: `${AppUtility.getDomain(country, true, true)}${match.url}`,
          title: recipe.displayName,
          socialLabels,
          facebook: facebook.enabled,
          twitter: twitter.enabled,
          pinterest: pinterest.enabled,
          linkedin: linkedin.enabled,
        }}
        foodMasthead={{
          personalPoints: pointsData,
          name: recipe.displayName,
          description: recipe.description,
          descriptionReadMore: translations.FOOD_DESCRIPTION_READ_MORE,
          descriptionReadLess: translations.FOOD_DESCRIPTION_READ_LESS,
          image: recipe.image.url,
          attributes: foodAttributes,
          alt: recipe.image.altTag || imageAltTag,
          infoLabel: translations.FOOD_INFO_LABEL,
          ...tooltipData,
          qrLinkUrl,
          qrLinkUrlLabel,
        }}
        ingredients={{
          headline: translations.FOOD_INGREDIENTS_LABEL,
          ingredients,
        }}
        instructions={{
          headline: translations.FOOD_INSTRUCTIONS_LABEL,
          instructions,
        }}
        note={{
          headline: translations.FOOD_NOTES_LABEL,
          text: recipe.note,
        }}
        sidebar={ads}
      />
      <FoodSuggestionsContainer suggestions={recipe.suggestions} />
      <EmailCaptureModalContainer />
      <MeteredContentContainer type="recipe" id={recipe.id} />
    </>
  );
};
