import _ from 'lodash';
import { VariationItemType } from '../../../ww.tests.ts';

export const cleanUpTestAutoRoutes = (
  updatableWWTests: any,
  country: string,
  language: string,
): any => {
  const uniquePaths: string[] = [];
  const arrayRebuild: { path: string; tests?: VariationItemType[] }[] = [];

  updatableWWTests[country][language].forEach(
    (items: { path: string; tests?: VariationItemType[] }) => {
      if (!uniquePaths.includes(items.path)) {
        uniquePaths.push(items.path);
        arrayRebuild.push(items);
      } else {
        arrayRebuild.forEach(
          (subItems: { path: string; tests?: VariationItemType[] }) => {
            if (subItems.path === items.path && items.tests?.[0]) {
              subItems.tests?.push(items.tests[0]);
            }
          },
        );
      }
    },
  );

  updatableWWTests[country][language] = _.cloneDeep(arrayRebuild);
  return updatableWWTests;
};
