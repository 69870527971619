import React, { useContext } from 'react';
import { Experiment } from '@ww-digital/xs-sdk-react';
import _ from 'lodash';

import type { EntitlementContextType } from '../../../context/entitlement.context';

import { BentoBoxSliceContainer } from '../BentoBoxSliceContainer/BentoBoxSliceContainer.tsx';
import { BodySlice } from '@ww-digital/web-palette-react/dist/components/Slice/BodySlice/BodySlice';
import { FootnoteSlice } from '@ww-digital/web-palette-react/dist/components/Slice/FootnoteSlice/FootnoteSlice';
import { IconCardSlice } from '@ww-digital/web-palette-react/dist/components/Slice/IconCardSlice/IconCardSlice';
import { ImageSlice } from '@ww-digital/web-palette-react/dist/components/Slice/ImageSlice/ImageSlice';
import { MemberCarouselSlice } from '@ww-digital/web-palette-react/dist/components/Slice/MemberCarouselSlice/MemberCarouselSlice';
import { NumberedStepsSlice } from '@ww-digital/web-palette-react/dist/components/Slice/NumberedStepsSlice/NumberedStepsSlice';
import { PullQuoteSlice } from '@ww-digital/web-palette-react/dist/components/Slice/PullQuoteSlice/PullQuoteSlice';
import { TestimonialSlice } from '@ww-digital/web-palette-react/dist/components/Slice/TestimonialSlice/TestimonialSlice';
import { VideoSlice } from '@ww-digital/web-palette-react/dist/components/Slice/VideoSlice/VideoSlice';
import { AnalyticsUtility } from '../../Utility/AnalyticsUtility.ts';
import { MastheadGridSliceContainer } from '../MastheadGridSliceContainer/MastheadGridSliceContainer.tsx';
import { BMICalculatorSliceContainer } from '../BMICalculatorSliceContainer/BMICalculatorSliceContainer.tsx';
import { CalloutSliceContainer } from '../CalloutSliceContainer/CalloutSliceContainer.tsx';
import { ColorBlockMastheadSliceContainer } from '../ColorBlockMastheadSliceContainer/ColorBlockMastheadSliceContainer.tsx';
import { ColumnIconListSliceContainer } from '../ColumnIconListSliceContainer/ColumnIconListSliceContainer.tsx';
import { ContentCardGridAutoSliceContainer } from '../ContentCardGridAutoSliceContainer/ContentCardGridAutoSliceContainer.tsx';
import { ContentCardGridManualSliceContainer } from '../ContentCardGridManualSliceContainer/ContentCardGridManualSliceContainer.tsx';
import { EmailCaptureSliceContainer } from '../EmailCaptureSliceContainer/EmailCaptureSliceContainer.tsx';
import { FAQSliceContainer } from '../FAQSliceContainer/FAQSliceContainer.tsx';
import { FeaturesListSliceContainer } from '../FeaturesListSliceContainer/FeaturesListSliceContainer.tsx';
import { FoodCardGridSliceContainer } from '../FoodCardGridSliceContainer/FoodCardGridSliceContainer.tsx';
import { FooterLegalSliceContainer } from '../FooterLegalSliceContainer/FooterLegalSliceContainer.tsx';
import { FooterMenuSliceContainer } from '../FooterMenuSliceContainer/FooterMenuSliceContainer.tsx';
import { GalleryCarouselSliceContainer } from '../GalleryCarouselSliceContainer/GalleryCarouselSliceContainer.tsx';
import { ImmersiveMastheadSliceContainer } from '../ImmersiveMastheadSliceContainer/ImmersiveMastheadSliceContainer.tsx';
import { LargeProductSquareSliceContainer } from '../LargeProductSquareSliceContainer/LargeProductSquareSliceContainer.tsx';
import { NavigationSliceContainer } from '../NavigationSliceContainer/NavigationSliceContainer.tsx';
import { PlansDifferentiatorSliceContainer } from '../PlansDifferentiatorSliceContainer/PlansDifferentiatorSliceContainer.tsx';
import { PlansDisclaimerSliceContainer } from '../PlansDisclaimerSliceContainer/PlansDisclaimerSliceContainer.tsx';
import { PlansDurationSliceContainer } from '../PlansDurationSliceContainer/PlansDurationSliceContainer.tsx';
import { PromotionBannerSliceContainer } from '../PromotionBannerSliceContainer/PromotionBannerSliceContainer.tsx';
import { SmallProductSquareSliceContainer } from '../SmallProductSquareSliceContainer/SmallProductSquareSliceContainer.tsx';
import { SmartPlateSliceContainer } from '../SmartPlateSliceContainer/SmartPlateSliceContainer.tsx';
import { SourcesSliceContainer } from '../SourcesSliceContainer/SourcesSliceContainer.tsx';
import { StoryStreamSliceContainer } from '../StoryStreamContainer/StoryStreamSliceContainer.tsx';
import { TableSliceContainer } from '../TableSliceContainer/TableSliceContainer.tsx';
import { TabsLinkSliceContainer } from '../TabsLinkSliceContainer/TabsLinkSliceContainer.tsx';
import { TestimonialCarouselSliceContainer } from '../TestimonialCarouselSliceContainer/TestimonialCarouselSliceContainer.tsx';
import { ToutSliceContainer } from '../ToutSliceContainer/ToutSliceContainer.tsx';
import { TwoColumnSliceContainer } from '../TwoColumnSliceContainer/TwoColumnSliceContainer.tsx';
import { VideoGridSliceContainer } from '../VideoGridSliceContainer/VideoGridSliceContainer.tsx';
import { VideoSlideshowSliceContainer } from '../VideoSlideshowSliceContainer/VideoSlideshowSliceContainer.tsx';
import { VideoMastheadSliceContainer } from '../VideoMastheadSliceContainer/VideoMastheadSliceContainer.tsx';
import { WeightHealthHomepageSliceContainer } from '../WeightHealthHomepageSliceContainer/WeightHealthHomepageSliceContainer.tsx';
import { WeightHealthHomepageBottomSliceContainer } from '../WeightHealthHomepageSliceContainer/WeightHealthHomepageBottomSliceContainer.tsx';
import { WeightHealthSliceContainer } from '../WeightHealthSliceContainer/WeightHealthSliceContainer.tsx';
import { EntitlementContext } from '../../../context/entitlement.context.ts';

export interface SliceContainerProps {
  slice: any;
  contentId?: string;
  category?: string;
  topLevelCategory?: string;
  region?: string;
  type: string;
  ref?: any;
  xsExperimentTextId?: string;
}

export interface SliceContainerComponentProps extends SliceContainerProps {
  daCategory: string;
}

interface ComponentMapType {
  BodySlice: typeof BodySlice;
  FootnoteSlice: typeof FootnoteSlice;
  IconCardSlice: typeof IconCardSlice;
  ImageSlice: typeof ImageSlice;
  MemberCarouselSlice: typeof MemberCarouselSlice;
  NumberedStepsSlice: typeof NumberedStepsSlice;
  PullQuoteSlice: typeof PullQuoteSlice;
  TestimonialSlice: typeof TestimonialSlice;
  VideoSlice: typeof VideoSlice;
}

export const SliceContainer = ({
  slice: sliceInit,
  contentId = '',
  category = '',
  topLevelCategory = '',
  region,
  type,
  xsExperimentTextId = '',
}: SliceContainerProps): JSX.Element | null => {
  const { entitlement } =
    useContext<EntitlementContextType>(EntitlementContext);
  // When adding support for a new slice, you must update the fragmentTypes.json file for
  // ApolloClient to add support for the new slice type.  You can find more info about how to do
  // this in the /src/components/Provider/Apollo/README.md.

  // Slices that need no extra data mapping. Just throw the data into the slice component.
  const sliceComponentMap: ComponentMapType = {
    BodySlice,
    FootnoteSlice,
    IconCardSlice,
    ImageSlice,
    MemberCarouselSlice,
    NumberedStepsSlice,
    PullQuoteSlice,
    TestimonialSlice,
    VideoSlice,
  };

  // Slices that have container components to provide some extra business logic or data mapping.
  const containerComponentMap: Record<
    string,
    React.FunctionComponent<SliceContainerComponentProps> | React.ComponentClass
  > = {
    BentoBoxSlice: BentoBoxSliceContainer,
    BMICalculatorSlice: BMICalculatorSliceContainer,
    CalloutSlice: CalloutSliceContainer,
    ColorBlockMastheadSlice: ColorBlockMastheadSliceContainer,
    ColumnIconListSlice: ColumnIconListSliceContainer,
    ContentCardGridAutoSlice: ContentCardGridAutoSliceContainer,
    ContentCardGridManualSlice: ContentCardGridManualSliceContainer,
    EmailCaptureSlice: EmailCaptureSliceContainer,
    FAQSlice: FAQSliceContainer,
    FeaturesListSlice: FeaturesListSliceContainer,
    FoodCardGridSlice: FoodCardGridSliceContainer,
    FooterLegalSlice: FooterLegalSliceContainer,
    FooterMenuSlice: FooterMenuSliceContainer,
    GalleryCarouselSlice: GalleryCarouselSliceContainer,
    ImmersiveMastheadSlice: ImmersiveMastheadSliceContainer,
    LargeProductSquareSlice: LargeProductSquareSliceContainer,
    NavigationSlice: NavigationSliceContainer,
    PlansDifferentiatorSlice: PlansDifferentiatorSliceContainer,
    PlansDisclaimerSlice: PlansDisclaimerSliceContainer,
    PlansDurationSlice: PlansDurationSliceContainer,
    PromotionBannerSlice: PromotionBannerSliceContainer,
    SmallProductSquareSlice: SmallProductSquareSliceContainer,
    SmartPlateSlice: SmartPlateSliceContainer,
    SourcesSlice: SourcesSliceContainer,
    StoryStreamSlice: StoryStreamSliceContainer,
    TableSlice: TableSliceContainer,
    TabsLinkSlice: TabsLinkSliceContainer,
    TestimonialCarouselSlice: TestimonialCarouselSliceContainer,
    ToutSlice: ToutSliceContainer,
    TwoColumnSlice: TwoColumnSliceContainer,
    VideoGridSlice: VideoGridSliceContainer,
    VideoMastheadSlice: VideoMastheadSliceContainer,
    VideoSlideshowSlice: VideoSlideshowSliceContainer,
    MastheadGridSlice: MastheadGridSliceContainer,
    WeightHealthHomepageSlice: WeightHealthHomepageSliceContainer,
    WeightHealthHomepageBottomSlice: WeightHealthHomepageBottomSliceContainer,
    WeightHealthSlice: WeightHealthSliceContainer,
  };

  const slice = _.cloneDeep(sliceInit);

  let exposure = <></>;

  if (xsExperimentTextId) {
    exposure = (
      <Experiment name={xsExperimentTextId}>
        <></>
      </Experiment>
    );
  }

  // If this slice has a direct component mapping, just throw all of the data straight into the component.
  if (slice.__typename in sliceComponentMap) {
    const SliceComponent =
      sliceComponentMap[slice.__typename as keyof ComponentMapType];

    const sliceComponent = <SliceComponent {...slice} />;

    return (
      <>
        {exposure}
        {sliceComponent}
      </>
    );
  }

  // If this slice has has a container, then pass the slice and other data into the container component.
  if (slice.__typename in containerComponentMap) {
    const SliceContainerComponent = containerComponentMap[slice.__typename];

    // Grab the analytics category as that is the most common reason a slice needs a container.
    const daCategory = AnalyticsUtility.formatCategory(
      entitlement,
      type,
      region,
      category,
    );

    const sliceContainerComponent = (
      <SliceContainerComponent
        slice={slice}
        daCategory={daCategory}
        category={category}
        topLevelCategory={topLevelCategory}
        region={region}
        type={type}
        contentId={contentId}
      />
    );

    return (
      <>
        {exposure}
        {sliceContainerComponent}
      </>
    );
  }

  return null;
};
