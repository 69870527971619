import Browser from '@ww-digital/web-palette-react/dist/components/Utility/Browser';

export const isMatchedDate = (
  startDate: string,
  endDate: string,
  timezone: string,
  dateStringFormat: string,
) => {
  let currentDate = new Date().toLocaleString(dateStringFormat, {
    timeZone: timezone,
  });
  // Use the preview date if it is set.
  if (Browser.isBrowser()) {
    const urlParams = new URLSearchParams(window.location.search);
    const xspreviewtests = urlParams.get('xspreviewtests');
    const xspreviewdate = urlParams.get('xspreviewdate');
    if (xspreviewtests && xspreviewdate) {
      currentDate = xspreviewdate;
    }
  }
  return (
    Date.parse(currentDate) >= Date.parse(startDate) &&
    Date.parse(currentDate) <= Date.parse(endDate)
  );
};
