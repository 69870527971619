import React from 'react';

import { WeightHealthSliceProps } from '@ww-digital/web-palette-react/dist/components/Slice/WeightHealthSlice/WeightHealthSlice';

import { WeightHealthSlice } from '@ww-digital/web-palette-react/dist/components/Slice/WeightHealthSlice/WeightHealthSlice';
import { AnalyticsUtility } from '../../Utility/AnalyticsUtility.ts';

interface WeightHealthSliceContainerProps {
  daCategory: string;
  slice: WeightHealthSliceProps;
}

export const WeightHealthSliceContainer = ({
  daCategory,
  slice,
}: WeightHealthSliceContainerProps): JSX.Element => {
  const sliceView = slice;

  // Button analytics
  sliceView.sectionThree?.button &&
    (sliceView.sectionThree.button.attributes = {
      'da-category':
        sliceView.sectionThree.button.attributes?.['da-category']?.trim() ||
        daCategory,
      'da-action':
        sliceView.sectionThree.button.attributes?.['da-action']?.trim() ||
        AnalyticsUtility.formatAction(
          'tout_cta',
          AnalyticsUtility.formatLabel(sliceView.sectionThree.button.children),
        ),
      'da-label':
        sliceView.sectionThree.button.attributes?.['da-label']?.trim() ||
        AnalyticsUtility.formatLabel(sliceView.sectionThree.button.children),
    });

  sliceView.sectionFour?.button &&
    (sliceView.sectionFour.button.attributes = {
      'da-category':
        sliceView.sectionFour.button.attributes?.['da-category']?.trim() ||
        daCategory,
      'da-action':
        sliceView.sectionFour.button.attributes?.['da-action']?.trim() ||
        AnalyticsUtility.formatAction(
          'tout_cta',
          AnalyticsUtility.formatLabel(sliceView.sectionFour.button.children),
        ),
      'da-label':
        sliceView.sectionFour.button.attributes?.['da-label']?.trim() ||
        AnalyticsUtility.formatLabel(sliceView.sectionFour.button.children),
    });

  sliceView.sectionFive?.button &&
    (sliceView.sectionFive.button.attributes = {
      'da-category':
        sliceView.sectionFive.button.attributes?.['da-category']?.trim() ||
        daCategory,
      'da-action':
        sliceView.sectionFive.button.attributes?.['da-action']?.trim() ||
        AnalyticsUtility.formatAction(
          'tout_cta',
          AnalyticsUtility.formatLabel(sliceView.sectionFive.button.children),
        ),
      'da-label':
        sliceView.sectionFive.button.attributes?.['da-label']?.trim() ||
        AnalyticsUtility.formatLabel(sliceView.sectionFive.button.children),
    });

  return <WeightHealthSlice {...sliceView} />;
};
