import { GlobalCalendarType, TemplatesAttributeType } from './types.ts';
import { isMatchedDate } from './isMatchedDate.ts';

export const getAlterPropsByDate = (
  attributeDates: TemplatesAttributeType['dates'],
  globalCalendar: GlobalCalendarType,
): TemplatesAttributeType['alterProps'] | undefined => {
  const dateStringFormat = globalCalendar.dateFormat;
  const timezone = globalCalendar.timezone;

  const dateAlteredProps = attributeDates?.find((date) => {
    return isMatchedDate(
      date.startDate,
      date.endDate,
      timezone,
      dateStringFormat,
    );
  });

  return dateAlteredProps;
};
