import { QueryStringUtility } from '../QueryStringUtility.ts';
import type { NormalizedExperiments } from '@ww-digital/xs-sdk';

export const getExperimentsPreviewHeader = (
  experimentsHeader: string | undefined,
  normalizedExperiments: NormalizedExperiments,
  urlSearchParams: string,
): string => {
  let experimentsHeaderOverride = '';
  const queryString = QueryStringUtility.getQueryParams(urlSearchParams);

  if (queryString.exptextid && queryString.varid) {
    const expTextid = queryString.exptextid;
    const varid = queryString.varid;

    if (!normalizedExperiments[expTextid]) {
      return experimentsHeaderOverride;
    }

    const expId = normalizedExperiments[expTextid].experimentId;
    experimentsHeaderOverride = `${expId}:${varid}`;

    if (queryString.override && queryString.override === 'false') {
      experimentsHeaderOverride += experimentsHeader
        ? `,${experimentsHeader}`
        : experimentsHeader;
    }
  }

  if (queryString.xspreviewtests) {
    experimentsHeaderOverride = queryString.xspreviewtests;
  }

  if (queryString.clearxspreview) {
    experimentsHeaderOverride = '';
  }

  return experimentsHeaderOverride;
};
