import { NormalizedExperiments } from '@ww-digital/xs-sdk';
import { ExperimentContextType } from '../../../context/experiment.context.ts';
import { TestItemType, VariationItemType } from '../../../ww.tests.ts';

export const testRegularExpression = (
  regexPath: string,
  pagePathname: string,
) => {
  const testStringForRegex: boolean =
    regexPath.startsWith('^') && regexPath.endsWith('$');
  const regex = new RegExp(regexPath);

  return testStringForRegex && regex.test(pagePathname);
};

export const filterExperimentsByPagePath = (
  mergedTests: any,
  country: string,
  language: string,
  pathname: string,
  normalizedExperiments: NormalizedExperiments,
  experimentsFranchise: string | undefined,
  experiments: ExperimentContextType['experiments'],
): void => {
  const testObj = (mergedTests[country][language] || []).filter(
    (test: TestItemType) =>
      pathname &&
      (test.path === pathname || testRegularExpression(test.path, pathname)) &&
      test.tests,
  );

  const filteredObj: VariationItemType[][] = [];
  let filteredObjFlat: VariationItemType[] = [];

  testObj.forEach((test: TestItemType) => {
    if (test.tests !== undefined) {
      filteredObj.push(test.tests);
    }
  });

  filteredObjFlat = filteredObj.flat(1);

  filteredObjFlat?.forEach((test: VariationItemType) => {
    const excludeNACO = test.excludeNACO && experimentsFranchise !== 'Y';
    const excludeFranchise =
      test.excludeFranchise && experimentsFranchise === 'Y';
    if (
      test.xs &&
      normalizedExperiments[test.xs] &&
      !excludeFranchise &&
      !excludeNACO
    ) {
      experiments[test.xs] = normalizedExperiments[test.xs];
    }
  });
};
