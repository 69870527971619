import { NormalizedExperiments } from '@ww-digital/xs-sdk';
import deepmerge from 'deepmerge';

export const combineTestAutoRoutes = (
  vsExpTextIDs: string[],
  normalizedExperiments: NormalizedExperiments,
  updatableWWTests: any,
): any => {
  vsExpTextIDs.forEach((expTextId) => {
    try {
      const attributes =
        normalizedExperiments[expTextId]?.variations?.control?.attributes;
      if (attributes?.autoRoute || attributes?.pageTargeting) {
        updatableWWTests = deepmerge(
          updatableWWTests,
          JSON.parse(
            normalizedExperiments[expTextId]?.variations?.control?.attributes
              ?.autoRoute.attributeValue ?? null,
          ),
        );
      }
    } catch (ex) {
      console.warn(ex);
    }
  });
  return updatableWWTests;
};
