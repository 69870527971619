import React from 'react';
import _ from 'lodash';

import type { BentoBoxHomepageProps } from '@ww-digital/web-palette-react/dist/components/BentoBoxHomepage/BentoBoxHomepage';

import { BentoBoxHomepage } from '@ww-digital/web-palette-react/dist/components/BentoBoxHomepage/BentoBoxHomepage';
import { BentoBoxSlice } from '@ww-digital/web-palette-react/dist/components/Slice/BentoBoxSlice/BentoBoxSlice';
import { AnalyticsUtility } from '../../Utility/AnalyticsUtility.ts';
import { bentoBoxHomepageData as bentoBoxHomepageDataV1 } from './BentoBoxSliceData.ts';
import { bentoBoxHomepageData as bentoBoxHomepageDataV2 } from './BentoBoxSliceDataV2.ts';

interface BentoBoxSliceContainerProps {
  daCategory: string;
  slice: BentoBoxHomepageProps;
}

export const BentoBoxSliceContainer = ({
  daCategory,
  slice,
}: BentoBoxSliceContainerProps): JSX.Element => {
  // Original BentoBoxHomepage has a version prop.  New productized slice does not.
  const isHomepage = slice && 'version' in slice;

  let sliceView = _.cloneDeep(slice);
  if (isHomepage) {
    const data =
      slice?.version === 'v2' ? bentoBoxHomepageDataV2 : bentoBoxHomepageDataV1;
    sliceView = _.merge({}, data, slice);
  }

  // Set up DA actions for 3-card and 4-card versions
  // These are for original tests.  It is preferable to set action in the CMS Editor.
  const daActions = {
    primaryCardOne: isHomepage ? 'masthead_bento_card1_points' : '',
    primaryCardTwo: isHomepage ? 'masthead_bento_card2_clinic' : '',
    primaryCardThree: isHomepage
      ? 'masthead_bento_card3_registereddietitians'
      : '',
    primaryCardFour: isHomepage ? 'masthead_bento_card4_workshops' : '',
  };

  // Add Button analytics
  sliceView.footerSection?.button &&
    (sliceView.footerSection.button.attributes = {
      'da-category':
        sliceView.footerSection.button.attributes?.['da-category']?.trim() ||
        daCategory,
      'da-action':
        sliceView.footerSection.button.attributes?.['da-action']?.trim() ||
        AnalyticsUtility.formatAction('masthead_bento_cta_take-our-quiz'),
      'da-label':
        sliceView.footerSection.button.attributes?.['da-label']?.trim() ||
        AnalyticsUtility.formatLabel(sliceView.footerSection.button.children),
    });

  sliceView.footerSection?.secondaryButton &&
    (sliceView.footerSection.secondaryButton.attributes = {
      'da-category':
        sliceView.footerSection.secondaryButton.attributes?.[
          'da-category'
        ]?.trim() || daCategory,
      'da-action':
        sliceView.footerSection.secondaryButton.attributes?.[
          'da-action'
        ]?.trim() ||
        AnalyticsUtility.formatAction('masthead_bento_cta_compare-memberships'),
      'da-label':
        sliceView.footerSection.secondaryButton.attributes?.[
          'da-label'
        ]?.trim() ||
        AnalyticsUtility.formatLabel(
          sliceView.footerSection.secondaryButton.children,
        ),
    });

  const section = sliceView.bentoBoxSectionV2 || sliceView.bentoBoxSection;

  section?.primaryCardOne?.link &&
    (section.primaryCardOne.link.attributes = {
      'da-category':
        section.primaryCardOne.link.attributes?.['da-category']?.trim() ||
        daCategory,
      'da-action':
        section.primaryCardOne.link.attributes?.['da-action']?.trim() ||
        AnalyticsUtility.formatAction(daActions.primaryCardOne),
      'da-label':
        section.primaryCardOne.link.attributes?.['da-label']?.trim() ||
        AnalyticsUtility.formatLabel(section.primaryCardOne.heading?.text),
    });

  section?.primaryCardTwo?.link &&
    (section.primaryCardTwo.link.attributes = {
      'da-category':
        section.primaryCardTwo.link.attributes?.['da-category']?.trim() ||
        daCategory,
      'da-action':
        section.primaryCardTwo.link.attributes?.['da-action']?.trim() ||
        AnalyticsUtility.formatAction(daActions.primaryCardTwo),
      'da-label':
        section.primaryCardTwo.link.attributes?.['da-label']?.trim() ||
        AnalyticsUtility.formatLabel(section.primaryCardTwo.heading?.text),
    });

  sliceView.bentoBoxSection?.primaryCardThree?.link &&
    (sliceView.bentoBoxSection.primaryCardThree.link.attributes = {
      'da-category':
        sliceView.bentoBoxSection.primaryCardThree.link.attributes?.[
          'da-category'
        ]?.trim() || daCategory,
      'da-action':
        sliceView.bentoBoxSection.primaryCardThree.link.attributes?.[
          'da-action'
        ]?.trim() || AnalyticsUtility.formatAction(daActions.primaryCardThree),
      'da-label':
        sliceView.bentoBoxSection.primaryCardThree.link.attributes?.[
          'da-label'
        ]?.trim() ||
        AnalyticsUtility.formatLabel(
          sliceView.bentoBoxSection.primaryCardThree.heading?.text,
        ),
    });

  sliceView.bentoBoxSection?.primaryCardFour?.link &&
    (sliceView.bentoBoxSection.primaryCardFour.link.attributes = {
      'da-category':
        sliceView.bentoBoxSection.primaryCardFour.link.attributes?.[
          'da-category'
        ]?.trim() || daCategory,
      'da-action':
        sliceView.bentoBoxSection.primaryCardFour.link.attributes?.[
          'da-action'
        ]?.trim() || AnalyticsUtility.formatAction(daActions.primaryCardFour),
      'da-label':
        sliceView.bentoBoxSection.primaryCardFour.link.attributes?.[
          'da-label'
        ]?.trim() ||
        AnalyticsUtility.formatLabel(
          sliceView.bentoBoxSection.primaryCardFour.heading?.text,
        ),
    });

  return isHomepage ? (
    <BentoBoxHomepage {...sliceView} />
  ) : (
    <BentoBoxSlice {...sliceView} bentoBoxSection={sliceView.bentoBoxSection} />
  );
};
